import Headroom from "headroom.js";

export const headroom = () => {
	// grab an element
	const header = document.querySelector("[data-main-header]");
	// construct an instance of Headroom, passing the element
	const options = {
		classes: {
			// when element is initialised
			initial: "main-header-init",
			// when scrolling up
			pinned: "main-header--pinned",
			// when scrolling down
			unpinned: "main-header--unpinned",
			// when above offset
			top: "main-header--top",
			// when below offset
			notTop: "main-header--not-top",
			// when at bottom of scroll area
			bottom: "main-header--bottom",
			// when not at bottom of scroll area
			notBottom: "main-header--not-bottom",
			// when frozen method has been called
			frozen: "main-header--frozen",
			// multiple classes are also supported with a space-separated list
			pinned: "main-header--pinned",
		},
	};
	const headroom = new Headroom(header, options);
	// initialise
	headroom.init();

	// if overlay menu active
	headroom.onUnpin = function () {
		if (header.classList.contains("text-white")) {
			header.classList.remove("main-header--unpinned");
			header.classList.add("main-header--pinned");
		}
	};
};
